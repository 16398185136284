<template>
  <div>
    <v-row no-gutters>
      <v-col
        :cols="$vuetify.breakpoint.width >= 340 ? 6 : 8"
        class="notification-row d-flex align-center"
        :class="{
          'justify-center': $vuetify.breakpoint.width >= 340,
        }"
      >
        <div
          class="notification-row-switch d-flex align-center"
          :class="{
            'justify-center': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <v-switch
            v-model="getClient"
            :disabled="loading"
            color="secondary"
            hide-details
            class="ma-0 pa-0"
            :label="!$vuetify.breakpoint.mdAndUp ? $trans(item.name) : null"
          />
        </div>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.width >= 340 ? 6 : 4"
        class="notification-row d-flex align-center justify-center"
      >
        <div
          class="notification-row-switch d-flex align-center"
          :class="{
            'justify-center': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <v-switch
            v-model="getTenant"
            :disabled="loading"
            color="secondary"
            hide-details
            class="ma-0 pa-0"
            :label="
              !$vuetify.breakpoint.mdAndUp && $vuetify.breakpoint.width >= 340
                ? $trans(item.name)
                : null
            "
          />
        </div>
      </v-col>
    </v-row>
    <notifications-card-line :slug="slug" />
  </div>
</template>

<script>
import NotificationsCardLine from "./NotificationsCardLine.vue";

export default {
  name: "NotificationsCardRow",
  components: {
    NotificationsCardLine,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    slug: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getClient: {
      get() {
        return (
          this.item.values.find((x) => x.key.split(".").includes("client")) &&
          this.item.values.find((x) => x.key.split(".").includes("client"))
            .status
        );
      },
      set(status) {
        this.item.values.find((x) =>
          x.key.split(".").includes("client"),
        ).status = status;
      },
    },
    getTenant: {
      get() {
        return (
          this.item.values.find((x) => x.key.split(".").includes("tenant")) &&
          this.item.values.find((x) => x.key.split(".").includes("tenant"))
            .status
        );
      },
      set(status) {
        this.item.values.find((x) =>
          x.key.split(".").includes("tenant"),
        ).status = status;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-row {
  height: 45px;
}

.notification-row-switch {
  min-width: 170px;
}

@media (max-width: 339px) {
  .notification-row-switch {
    min-width: 0;
  }
}
</style>
