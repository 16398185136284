<template>
  <v-container fluid class="rounded-lg text-center text-sm-left blue-light">
    <v-row class="px-6" align="center" justify="center">
      <v-col class="text-center">
        <div style="height: 230px" class="mt-4">
          <div>
            <h6 class="text-h6 mt-4 py-6">
              {{ $trans("notifications_sms_email") }}
            </h6>
            <div class="mt-2" v-html="$trans('notifications_reminder_first')" />
            <div
              class="mt-2"
              v-html="$trans('notifications_reminder_second')"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-14 py-3 font-weight-medium" no-gutters>
      <v-col>
        <div class="d-flex justify-center align-center">
          {{ $trans("notification") }}
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <div class="border-bottom-long" />
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center notification-row">
        {{ $trans("booking_created") }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <div class="border-bottom-long" />
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center notification-row">
        {{ $trans("booking_updated") }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <div class="border-bottom-long" />
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center notification-row">
        {{ $trans("booking_deleted") }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <div class="border-bottom-long" />
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center notification-row">
        {{ $trans("booking_reminder") }}
      </v-col>
    </v-row>

    <v-row no-gutters>
      <div class="border-bottom-long" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NotificationsCardStatic",
  computed: {
    ...mapGetters({
      adminConfiguration: "setup/getAdminConfiguration",
    }),
  },
  methods: {
    openBookingRemindersManager() {},
  },
};
</script>

<style lang="scss" scoped>
.notification-row {
  height: 45px;
}
</style>
