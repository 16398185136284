<template>
  <v-container
    fluid
    class="rounded-lg text-center text-sm-left"
    :class="[slug === 'email-notifications' ? 'blue-light' : 'green-light']"
  >
    <v-row class="px-6" align="center" justify="center">
      <v-col class="text-center">
        <div style="height: 230px">
          <div>
            <h6 class="text-h6 mt-4 pa-6">
              {{ $trans(headerName) }}
            </h6>
            <div
              class="text-subtitle-2"
              v-text="$trans('notification_first_label')"
            />
            <div class="blue--text text-h3">
              {{ slug === "email-notifications" ? usage.email : usage.sms }}
            </div>
            <div class="text-subtitle-2">
              {{
                slug === "email-notifications"
                  ? $trans("notification_second_label_email")
                  : $trans("notification_second_label_sms")
              }}
            </div>
          </div>
          <div class="mt-4">
            <v-btn
              outlined
              :disabled="loading"
              color="blue"
              @click="buyNotificationButtonClicked"
            >
              {{ $trans("buy_more") }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-14 py-3" no-gutters>
      <v-col class="d-flex justify-center align-center">
        <span class="font-weight-medium">{{ $trans("notify_client") }}</span>
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <span class="font-weight-medium">{{ $trans("notify_tenant") }}</span>
      </v-col>
    </v-row>
    <notifications-card-line :slug="slug" />
    <notifications-card-row
      v-for="element in items"
      :key="element.name"
      :loading="loading"
      :item="element"
      :slug="slug"
    />
    <v-row class="py-3 mt-2">
      <v-col class="d-flex align-center justify-center">
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="primary"
          depressed
          @click="save"
        >
          {{ $trans("save") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import NotificationsCardLine from "@/views/dashboard/pages/Notifications/components/NotificationsCardLine.vue";
import NotificationsCardRow from "@/views/dashboard/pages/Notifications/components/NotificationsCardRow.vue";

export default {
  name: "NotificationsCard",
  components: {
    NotificationsCardRow,
    NotificationsCardLine,
  },
  mixins: [sharedActions],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    slug: {
      type: String,
      default: "",
    },
    availableExtraPackages: {
      type: Array,
      default: () => [],
    },
    tenantConfigPackages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [
        { name: "created", values: [], id: null },
        { name: "updated", values: [], id: null },
        { name: "deleted", values: [], id: null },
        { name: "reminder", values: [], id: null },
      ],
    };
  },
  computed: {
    ...mapGetters({
      usage: "notification/getUsage",
    }),
    headerName() {
      return this.slug.replace(/-/g, "_");
    },
    packageObject() {
      return this.availableExtraPackages.find(
        (x) => x.service.slug === this.slug,
      );
    },
  },
  watch: {
    tenantConfigPackages(val) {
      this.processData(val);
    },
  },
  created() {
    this.processData(this.tenantConfigPackages);
  },
  methods: {
    ...mapActions({
      update: "notification/update",
      fetchCurrentTenantPlan: "packages/fetchCurrentTenantPlan",
    }),
    async updateNotification(obj) {
      this.$emit("loading", true);

      pushEvent("updateNotificationsSettings");

      await this.update(obj)
        .then(() => {
          this.fetchCurrentTenantPlan().then(() => {
            successNotification("notification_save_success");
            this.$emit("loading", false);
          });
        })
        .catch((error) => {
          errorNotification("operation_failed", error);
          this.$emit("loading", false);
        });
    },
    findAndReturnChangedStatuses() {
      const original = this.items.map((x) => x.values).flat();

      original.forEach((el) => {
        el.id = this.items[0].id;
      });

      return original;
    },
    save() {
      const statuses = this.findAndReturnChangedStatuses();
      this.updateNotification({ preferences: statuses });
    },
    processData(tenantConfigPackages) {
      const resCopy = this.$helpers.cloneObject(tenantConfigPackages);
      const found = resCopy.find((x) => x.package.service.slug === this.slug);

      if (found) {
        for (const prop in found.preferences) {
          const arr = prop.split(".");
          const key = arr.join(".");

          for (let i = 0; i < this.items.length; i++) {
            if (arr.includes(this.items[i].name)) {
              const obj = this.items.find((x) => x.name === this.items[i].name);

              if (!obj.values.find((x) => x.key === key)) {
                // Add the value if it doesn't exist already
                obj.values.push({
                  key,
                  status: found.preferences[prop],
                });
                obj.id = found.id;
              }
            }
          }
        }
      }

      this.$emit("loading", false);
    },
    buyNotificationButtonClicked() {
      this.openDialog({
        type: dialogTypes.BUY_NOTIFICATION_DIALOG,
        size: dialogSize.FULL_SCREEN,
        data: this.packageObject,
      });
    },
  },
};
</script>
<style lang="scss">
.border-bottom-long {
  border-bottom: 1px solid #bcd0da;
  width: 100%;
}

.border-bottom-regular {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #bcd0da;
}

.relative {
  position: relative;
}

@media (max-width: 960px) {
  .relative-only-mobile {
    position: relative;
  }
  .border-bottom-long {
    width: 100%;
    overflow: hidden;
  }
}
</style>
