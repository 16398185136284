var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"notification-row d-flex align-center",class:{
        'justify-center': _vm.$vuetify.breakpoint.width >= 340,
      },attrs:{"cols":_vm.$vuetify.breakpoint.width >= 340 ? 6 : 8}},[_c('div',{staticClass:"notification-row-switch d-flex align-center",class:{
          'justify-center': _vm.$vuetify.breakpoint.mdAndUp,
        }},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"disabled":_vm.loading,"color":"secondary","hide-details":"","label":!_vm.$vuetify.breakpoint.mdAndUp ? _vm.$trans(_vm.item.name) : null},model:{value:(_vm.getClient),callback:function ($$v) {_vm.getClient=$$v},expression:"getClient"}})],1)]),_c('v-col',{staticClass:"notification-row d-flex align-center justify-center",attrs:{"cols":_vm.$vuetify.breakpoint.width >= 340 ? 6 : 4}},[_c('div',{staticClass:"notification-row-switch d-flex align-center",class:{
          'justify-center': _vm.$vuetify.breakpoint.mdAndUp,
        }},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"disabled":_vm.loading,"color":"secondary","hide-details":"","label":!_vm.$vuetify.breakpoint.mdAndUp && _vm.$vuetify.breakpoint.width >= 340
              ? _vm.$trans(_vm.item.name)
              : null},model:{value:(_vm.getTenant),callback:function ($$v) {_vm.getTenant=$$v},expression:"getTenant"}})],1)])],1),_c('notifications-card-line',{attrs:{"slug":_vm.slug}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }