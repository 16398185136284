<template>
  <v-row
    :class="slug === 'sms-notifications' ? ' relative' : 'relative-only-mobile'"
    no-gutters
  >
    <v-col>
      <div
        :class="
          slug === 'email-notifications'
            ? 'border-bottom-long'
            : 'border-bottom-regular'
        "
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NotificationsCardLine",
  props: {
    slug: {
      type: String,
      default: "",
    },
  },
};
</script>
