const allCountries = [
  ["Afghanistan (‫افغانستان‬‎)", "af", "93", null, null, "0701234567"],
  ["Albania (Shqipëri)", "al", "355", null, null, "0672123456"],
  ["Algeria (‫الجزائر‬‎)", "dz", "213", null, null, "0551234567"],
  ["American Samoa", "as", "1", 5, ["684"], "6847331234"],
  ["Andorra", "ad", "376", null, null, "312345"],
  ["Angola", "ao", "244", null, null, "923123456"],
  ["Anguilla", "ai", "1", 6, ["264"], "2642351234"],
  ["Antigua and Barbuda", "ag", "1", 7, ["268"], "2684641234"],
  ["Argentina", "ar", "54", null, null, "0111523456789"],
  ["Armenia (Հայաստան)", "am", "374", null, null, "077123456"],
  ["Aruba", "aw", "297", null, null, "5601234"],
  ["Australia", "au", "61", 0, null, "0412345678"],
  ["Austria (Österreich)", "at", "43", null, null, "0664123456"],
  ["Azerbaijan (Azərbaycan)", "az", "994", null, null, "0401234567"],
  ["Bahamas", "bs", "1", 8, ["242"], "2423591234"],
  ["Bahrain (‫البحرين‬‎)", "bh", "973", null, null, "36001234"],
  ["Bangladesh (বাংলাদেশ)", "bd", "880", null, null, "01812345678"],
  ["Barbados", "bb", "1", 9, ["246"], "2462501234"],
  ["Belarus (Беларусь)", "by", "375", null, null, "80294911911"],
  ["Belgium (België)", "be", "32", null, null, "0470123456"],
  ["Belize", "bz", "501", null, null, "6221234"],
  ["Benin (Bénin)", "bj", "229", null, null, "90011234"],
  ["Bermuda", "bm", "1", 10, ["441"], "4413701234"],
  ["Bhutan (འབྲུག)", "bt", "975", null, null, "17123456"],
  ["Bolivia", "bo", "591", null, null, "71234567"],
  [
    "Bosnia and Herzegovina (Босна и Херцеговина)",
    "ba",
    "387",
    null,
    null,
    "061123456",
  ],
  ["Botswana", "bw", "267", null, null, "71123456"],
  ["Brazil (Brasil)", "br", "55", null, null, "119612345673"],
  ["British Indian Ocean Territory", "io", "246", null, null, "3801234"],
  ["British Virgin Islands", "vg", "1", 11, ["284"], "2843001234"],
  ["Brunei", "bn", "673", null, null, "7123456"],
  ["Bulgaria (България)", "bg", "359", null, null, "048123456"],
  ["Burkina Faso", "bf", "226", null, null, "70123456"],
  ["Burundi (Uburundi)", "bi", "257", null, null, "79561234"],
  ["Cambodia (កម្ពុជា)", "kh", "855", null, null, "091234567"],
  ["Cameroon (Cameroun)", "cm", "237", null, null, "671234567"],
  [
    "Canada",
    "ca",
    "1",
    1,
    [
      "204",
      "226",
      "236",
      "249",
      "250",
      "289",
      "306",
      "343",
      "365",
      "387",
      "403",
      "416",
      "418",
      "431",
      "437",
      "438",
      "450",
      "506",
      "514",
      "519",
      "548",
      "579",
      "581",
      "587",
      "604",
      "613",
      "639",
      "647",
      "672",
      "705",
      "709",
      "742",
      "778",
      "780",
      "782",
      "807",
      "819",
      "825",
      "867",
      "873",
      "902",
      "905",
    ],
    "5062345678",
  ],
  ["Cape Verde (Kabu Verdi)", "cv", "238", null, null, "9911234"],
  ["Caribbean Netherlands", "bq", "599", 1, ["3", "4", "7"], "3181234"],
  ["Cayman Islands", "ky", "1", 12, ["345"], "3453231234"],
  [
    "Central African Republic (République centrafricaine)",
    "cf",
    "236",
    null,
    null,
    "70012345",
  ],
  ["Chad (Tchad)", "td", "235", null, null, "63012345"],
  ["Chile", "cl", "56", null, null, "221234567"],
  ["China (中国)", "cn", "86", null, null, "13123456789"],
  ["Christmas Island", "cx", "61", 2, null, "0412345678"],
  ["Cocos (Keeling) Islands", "cc", "61", 1, null, "0412345678"],
  ["Colombia", "co", "57", null, null, "3211234567"],
  ["Comoros (‫جزر القمر‬‎)", "km", "269", null, null, "3212345"],
  [
    "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    "cd",
    "243",
    null,
    null,
    "0991234567",
  ],
  [
    "Congo (Republic) (Congo-Brazzaville)",
    "cg",
    "242",
    null,
    null,
    "061234567",
  ],
  ["Cook Islands", "ck", "682", null, null, "71234"],
  ["Costa Rica", "cr", "506", null, null, "83123456"],
  ["Côte d’Ivoire", "ci", "225", null, null, "01234567"],
  ["Croatia (Hrvatska)", "hr", "385", null, null, "0921234567"],
  ["Cuba", "cu", "53", null, null, "051234567"],
  ["Curaçao", "cw", "599", 0, null, "95181234"],
  ["Cyprus (Κύπρος)", "cy", "357", null, null, "96123456"],
  ["Czech Republic (Česká republika)", "cz", "420", null, null, "601123456"],
  ["Denmark (Danmark)", "dk", "45", null, null, "32123456"],
  ["Djibouti", "dj", "253", null, null, "77831001"],
  ["Dominica", "dm", "1", 13, ["767"], "7672251234"],
  [
    "Dominican Republic (República Dominicana)",
    "do",
    "1",
    2,
    ["809", "829", "849"],
    "8092345678",
  ],
  ["Ecuador", "ec", "593", null, null, "0991234567"],
  ["Egypt (‫مصر‬‎)", "eg", "20", null, null, "01001234567"],
  ["El Salvador", "sv", "503", null, null, "70123456"],
  [
    "Equatorial Guinea (Guinea Ecuatorial)",
    "gq",
    "240",
    null,
    null,
    "222123456",
  ],
  ["Eritrea", "er", "291", null, null, "07123456"],
  ["Estonia (Eesti)", "ee", "372", null, null, "51234567"],
  ["Ethiopia", "et", "251", null, null, "0911234567"],
  ["Falkland Islands (Islas Malvinas)", "fk", "500", null, null, "51234"],
  ["Faroe Islands (Føroyar)", "fo", "298", null, null, "211234"],
  ["Fiji", "fj", "679", null, null, "7012345"],
  ["Finland (Suomi)", "fi", "358", 0, null, "0412345678"],
  ["France", "fr", "33", null, null, "0612345678"],
  ["French Guiana (Guyane française)", "gf", "594", null, null, "0694201234"],
  [
    "French Polynesia (Polynésie française)",
    "pf",
    "689",
    null,
    null,
    "87123456",
  ],
  ["Gabon", "ga", "241", null, null, "06031234"],
  ["Gambia", "gm", "220", null, null, "3012345"],
  ["Georgia (საქართველო)", "ge", "995", null, null, "555123456"],
  ["Germany (Deutschland)", "de", "49", null, null, "01512456789"],
  ["Ghana (Gaana)", "gh", "233", null, null, "0231234567"],
  ["Gibraltar", "gi", "350", null, null, "57123456"],
  ["Greece (Ελλάδα)", "gr", "30", null, null, "6912345678"],
  ["Greenland (Kalaallit Nunaat)", "gl", "299", null, null, "221234"],
  ["Grenada", "gd", "1", 14, ["473"], "4734031234"],
  ["Guadeloupe", "gp", "590", 0, null, "0690001234"],
  ["Guam", "gu", "1", 15, ["671"], "6713001234"],
  ["Guatemala", "gt", "502", null, null, "51234567"],
  ["Guernsey", "gg", "44", 1, ["1481", "7781", "7839", "7911"], "07781123456"],
  ["Guinea (Guinée)", "gn", "224", null, null, "601123456"],
  ["Guinea-Bissau (Guiné Bissau)", "gw", "245", null, null, "955012345"],
  ["Guyana", "gy", "592", null, null, "6091234"],
  ["Haiti", "ht", "509", null, null, "34101234"],
  ["Honduras", "hn", "504", null, null, "91234567"],
  ["Hong Kong (香港)", "hk", "852", null, null, "51234567"],
  ["Hungary (Magyarország)", "hu", "36", null, null, "06201234567"],
  ["Iceland (Ísland)", "is", "354", null, null, "06201234567"],
  ["India (भारत)", "in", "91", null, null, "08123456789"],
  ["Indonesia", "id", "62", null, null, "0812345678"],
  ["Iran (‫ایران‬‎)", "ir", "98", null, null, "09123456789"],
  ["Iraq (‫العراق‬‎)", "iq", "964", null, null, "07912345678"],
  ["Ireland", "ie", "353", null, null, "0850123456"],
  [
    "Isle of Man",
    "im",
    "44",
    2,
    ["1624", "74576", "7524", "7924", "7624"],
    "07924123456",
  ],
  ["Israel (‫ישראל‬‎)", "il", "972", null, null, "0502345678"],
  ["Italy (Italia)", "it", "39", 0, null, "3123456789"],
  ["Jamaica", "jm", "1", 4, ["876", "658"], "8762101234"],
  ["Japan (日本)", "jp", "81", null, null, "09012345678"],
  [
    "Jersey",
    "je",
    "44",
    3,
    ["1534", "7509", "7700", "7797", "7829", "7937"],
    "08898812345",
  ],
  ["Jordan (‫الأردن‬‎)", "jo", "962", null, null, "0790123456"],
  ["Kazakhstan (Казахстан)", "kz", "7", 1, ["33", "7"], "87710009998"],
  ["Kenya", "ke", "254", null, null, "0712123456"],
  ["Kiribati", "ki", "686", null, null, "71001234"],
  ["Kosovo", "xk", "383", null, null, "043201234"],
  ["Kuwait (‫الكويت‬‎)", "kw", "965", null, null, "50012345"],
  ["Kyrgyzstan (Кыргызстан)", "kg", "996", null, null, "0700123456"],
  ["Laos (ລາວ)", "la", "856", null, null, "02023123456"],
  ["Latvia (Latvija)", "lv", "371", null, null, "21234567"],
  ["Lebanon (‫لبنان‬‎)", "lb", "961", null, null, "71123456"],
  ["Lesotho", "ls", "266", null, null, "50123456"],
  ["Liberia", "lr", "231", null, null, "0770123456"],
  ["Libya (‫ليبيا‬‎)", "ly", "218", null, null, "09112345678"],
  ["Liechtenstein", "li", "423", null, null, "660234567"],
  ["Lithuania (Lietuva)", "lt", "370", null, null, "861234567"],
  ["Luxembourg", "lu", "352", null, null, "628123456"],
  ["Macau (澳門)", "mo", "853", null, null, "66123456"],
  ["Macedonia (FYROM) (Македонија)", "mk", "389", null, null, "072345678"],
  ["Madagascar (Madagasikara)", "mg", "261", null, null, "0321234567"],
  ["Malawi", "mw", "265", null, null, "0991234567"],
  ["Malaysia", "my", "60", null, null, "0123456789"],
  ["Maldives", "mv", "960", null, null, "7712345"],
  ["Mali", "ml", "223", null, null, "65012345"],
  ["Malta", "mt", "356", null, null, "96961234"],
  ["Marshall Islands", "mh", "692", null, null, "2351234"],
  ["Martinique", "mq", "596", null, null, "0696201234"],
  ["Mauritania (‫موريتانيا‬‎)", "mr", "222", null, null, "22123456"],
  ["Mauritius (Moris)", "mu", "230", null, null, "52512345"],
  ["Mayotte", "yt", "262", 1, ["269", "639"], "0639012345"],
  ["Mexico (México)", "mx", "52", null, null, "2221234567"],
  ["Micronesia", "fm", "691", null, null, "3501234"],
  ["Moldova (Republica Moldova)", "md", "373", null, null, "062112345"],
  ["Monaco", "mc", "377", null, null, "0612345678"],
  ["Mongolia (Монгол)", "mn", "976", null, null, "88123456"],
  ["Montenegro (Crna Gora)", "me", "382", null, null, "067622901"],
  ["Montserrat", "ms", "1", 16, ["664"], "6644923456"],
  ["Morocco (‫المغرب‬‎)", "ma", "212", 0, null, "0650123456"],
  ["Mozambique (Moçambique)", "mz", "258", null, null, "821234567"],
  ["Myanmar (Burma) (မြန်မာ)", "mm", "95", null, null, "092123456"],
  ["Namibia (Namibië)", "na", "264", null, null, "0811234567"],
  ["Nauru", "nr", "674", null, null, "5551234"],
  ["Nepal (नेपाल)", "np", "977", null, null, "9841234567"],
  ["Netherlands (Nederland)", "nl", "31", null, null, "0612345678"],
  ["New Caledonia (Nouvelle-Calédonie)", "nc", "687", null, null, "75.12.34"],
  ["New Zealand", "nz", "64", null, null, "0211234567"],
  ["Nicaragua", "ni", "505", null, null, "81234567"],
  ["Niger (Nijar)", "ne", "227", null, null, "93123456"],
  ["Nigeria", "ng", "234", null, null, "08021234567"],
  ["Niue", "nu", "683", null, null, "8884012"],
  ["Norfolk Island", "nf", "672", null, null, "381234"],
  [
    "North Korea (조선 민주주의 인민 공화국)",
    "kp",
    "850",
    null,
    null,
    "01921234567",
  ],
  ["Northern Mariana Islands", "mp", "1", 17, ["670"], "6702345678"],
  ["Norway (Norge)", "no", "47", 0, null, "40612345"],
  ["Oman (‫عُمان‬‎)", "om", "968", null, null, "92123456"],
  ["Pakistan (‫پاکستان‬‎)", "pk", "92", null, null, "03012345678"],
  ["Palau", "pw", "680", null, null, "6201234"],
  ["Palestine (‫فلسطين‬‎)", "ps", "970", null, null, "0599123456"],
  ["Panama (Panamá)", "pa", "507", null, null, "61234567"],
  ["Papua New Guinea", "pg", "675", null, null, "70123456"],
  ["Paraguay", "py", "595", null, null, "0961456789"],
  ["Peru (Perú)", "pe", "51", null, null, "912345678"],
  ["Philippines", "ph", "63", null, null, "09051234567"],
  ["Poland (Polska)", "pl", "48", null, null, "512345678"],
  ["Portugal", "pt", "351", null, null, "912345678"],
  ["Puerto Rico", "pr", "1", 3, ["787", "939"], "7872345678"],
  ["Qatar (‫قطر‬‎)", "qa", "974", null, null, "33123456"],
  ["Réunion (La Réunion)", "re", "262", 0, null, "0692123456"],
  ["Romania (România)", "ro", "40", null, null, "0712034567"],
  ["Russia (Россия)", "ru", "7", 0, null, "89123456789"],
  ["Rwanda", "rw", "250", null, null, "0721123456"],
  ["Saint Barthélemy", "bl", "590", 1, null, "0690001234"],
  ["Saint Helena", "sh", "290", null, null, "51234"],
  ["Saint Kitts and Nevis", "kn", "1", 18, ["869"], "8697652917"],
  ["Saint Lucia", "lc", "1", 19, ["758"], "7582845678"],
  [
    "Saint Martin (Saint-Martin (partie française))",
    "mf",
    "590",
    2,
    null,
    "0690001234",
  ],
  [
    "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    "pm",
    "508",
    null,
    null,
    "0551234",
  ],
  ["Saint Vincent and the Grenadines", "vc", "1", 20, ["784"], "7844301234"],
  ["Samoa", "ws", "685", null, null, "7212345"],
  ["San Marino", "sm", "378", null, null, "66661212"],
  [
    "São Tomé and Príncipe (São Tomé e Príncipe)",
    "st",
    "239",
    null,
    null,
    "9812345",
  ],
  [
    "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    "sa",
    "966",
    null,
    null,
    "0512345678",
  ],
  ["Senegal (Sénégal)", "sn", "221", null, null, "701234567"],
  ["Serbia (Србија)", "rs", "381", null, null, "0601234567"],
  ["Seychelles", "sc", "248", null, null, "2510123"],
  ["Sierra Leone", "sl", "232", null, null, "025123456"],
  ["Singapore", "sg", "65", null, null, "81234567"],
  ["Sint Maarten", "sx", "1", 21, ["721"], "7215025678"],
  ["Slovakia (Slovensko)", "sk", "421", null, null, "0912123456"],
  ["Slovenia (Slovenija)", "si", "386", null, null, "031234567"],
  ["Solomon Islands", "sb", "677", null, null, "7421234"],
  ["Somalia (Soomaaliya)", "so", "252", null, null, "71123456"],
  ["South Africa", "za", "27", null, null, "0711234567"],
  ["South Korea (대한민국)", "kr", "82", null, null, "01020000000"],
  ["South Sudan (‫جنوب السودان‬‎)", "ss", "211", null, null, "0977123456"],
  ["Spain (España)", "es", "34", null, null, "612345678"],
  ["Sri Lanka (ශ්‍රී ලංකාව)", "lk", "94", null, null, "0712345678"],
  ["Sudan (‫السودان‬‎)", "sd", "249", null, null, "0911231234"],
  ["Suriname", "sr", "597", null, null, "7412345"],
  ["Svalbard and Jan Mayen", "sj", "47", 1, ["79"], "41234567"],
  ["Swaziland", "sz", "268", null, null, "76123456"],
  ["Sweden (Sverige)", "se", "46", null, null, "0701234567"],
  ["Switzerland (Schweiz)", "ch", "41", null, null, "0781234567"],
  ["Syria (‫سوريا‬‎)", "sy", "963", null, null, "0944567890"],
  ["Taiwan (台灣)", "tw", "886", null, null, "0912345678"],
  ["Tajikistan", "tj", "992", null, null, "917123456"],
  ["Tanzania", "tz", "255", null, null, "0621234567"],
  ["Thailand (ไทย)", "th", "66", null, null, "0812345678"],
  ["Timor-Leste", "tl", "670", null, null, "77212345"],
  ["Togo", "tg", "228", null, null, "90112345"],
  ["Tokelau", "tk", "690", null, null, "7290"],
  ["Tonga", "to", "676", null, null, "7715123"],
  ["Trinidad and Tobago", "tt", "1", 22, ["868"], "8682911234"],
  ["Tunisia (‫تونس‬‎)", "tn", "216", null, null, "20123456"],
  ["Turkey (Türkiye)", "tr", "90", null, null, "05012345678"],
  ["Turkmenistan", "tm", "993", null, null, "866123456"],
  ["Turks and Caicos Islands", "tc", "1", 23, ["649"], "6492311234"],
  ["Tuvalu", "tv", "688", null, null, "901234"],
  ["U.S. Virgin Islands", "vi", "1", 24, ["340"], "3406421234"],
  ["Uganda", "ug", "256", null, null, "0712345678"],
  ["Ukraine (Україна)", "ua", "380", null, null, "0501234567"],
  [
    "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    "ae",
    "971",
    null,
    null,
    "0501234567",
  ],
  ["United Kingdom", "gb", "44", 0, null, "07400123456"],
  ["United States", "us", "1", 0, null, "2015550123"],
  ["Uruguay", "uy", "598", null, null, "094231234"],
  ["Uzbekistan (Oʻzbekiston)", "uz", "998", null, null, "8912345678"],
  ["Vanuatu", "vu", "678", null, null, "5912345"],
  ["Vatican City (Città del Vaticano)", "va", "39", 1, ["06698"], "3123456789"],
  ["Venezuela", "ve", "58", null, null, "04121234567"],
  ["Vietnam (Việt Nam)", "vn", "84", null, null, "0912345678"],
  ["Wallis and Futuna (Wallis-et-Futuna)", "wf", "681", null, null, "501234"],
  [
    "Western Sahara (‫الصحراء الغربية‬‎)",
    "eh",
    "212",
    1,
    ["5288", "5289"],
    "0650123456",
  ],
  ["Yemen (‫اليمن‬‎)", "ye", "967", null, null, "0712345678"],
  ["Zambia", "zm", "260", null, null, "0955123456"],
  ["Zimbabwe", "zw", "263", null, null, "0712345678"],
  ["Åland Islands", "ax", "358", 1, ["18"], "0412345678"],
];

export default function getCountries() {
  const result = [];
  for (let i = 0; i < allCountries.length; i++) {
    const c = allCountries[i];
    result.push({
      name: c[0],
      iso2: c[1],
      iso2UpperCase: c[1].toUpperCase(),
      dialCode: c[2],
      priority: c[3] || 0,
      areaCodes: c[4] || null,
      showDialCode:
        c[4] && c[4].length === 1 ? `+${c[2]}${c[4][0]}` : `+${c[2]}`,
      examplePhoneNumber: c[5],
    });
  }
  return result;
}
