<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col class="d-none d-md-flex" lg="4" md="4">
          <notifications-card-static />
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <notifications-card
            :loading="isLoadingFirst"
            slug="email-notifications"
            :available-extra-packages="extraPackagesUnitPriced"
            :tenant-config-packages="tenantConfigPackages"
            @loading="isLoadingFirst = $event"
          />
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <notifications-card
            :loading="isLoadingSecond"
            slug="sms-notifications"
            :available-extra-packages="extraPackagesUnitPriced"
            :tenant-config-packages="tenantConfigPackages"
            @loading="isLoadingSecond = $event"
          />
        </v-col>
      </v-row>
    </v-container>
    <manage-notification-settings class="mb-8" />
  </div>
</template>

<script>
import planActions from "@/calendesk/mixins/planActions";
import NotificationsCardStatic from "@/views/dashboard/pages/Notifications/components/NotificationsCardStatic.vue";
import NotificationsCard from "@/views/dashboard/pages/Notifications/components/NotificationsCard.vue";
import ManageNotificationSettings from "@/views/dashboard/pages/Notifications/components/ManageNotificationSettings.vue";

export default {
  name: "NotificationsSettings",
  components: {
    ManageNotificationSettings,
    NotificationsCard,
    NotificationsCardStatic,
  },
  mixins: [planActions],
  data() {
    return {
      isLoadingFirst: false,
      isLoadingSecond: false,
    };
  },
  created() {
    this.isLoadingFirst = true;
    this.isLoadingSecond = true;
    this.fetchUsage().finally(() => {
      this.isLoadingFirst = false;
      this.isLoadingSecond = false;
    });

    if (this.adminConfiguration) {
      this.bookingsReminderTimeBefore =
        this.adminConfiguration.bookings_reminder_time_before;
    }
  },
};
</script>
<style lang="scss">
.card-padding {
  padding: 40px;
}
</style>
